<template>
  <card-container>
    <v-toolbar color="#f1b434">
      <v-flex xs6>
        <v-toolbar-title>Schema</v-toolbar-title>
      </v-flex>
      <v-flex xs-6 offset-xs6>
        <v-select
          :items="classes"
          v-model="classString"
          color="#f1b434"
        />
      </v-flex>
    </v-toolbar>
    <v-list>
      <v-list-tile
        v-for="(nationlist, index) in nations"
        :key="index">
        <v-list-tile-avatar
          v-for="(nation, colindex) in nationlist"
          :key="'avatar' + index + colindex"
          :style="`position: absolute; left: ${getindent(colindex, nationlist.length)}em;`">
          <img :src="nation.iconURL">
        </v-list-tile-avatar>
        <v-list-tile-content :style="`position: relative; left: ${getindent()}em;`">
          <v-list-tile-title>{{ nationlist.map(nation => nation.name).join('/')}}</v-list-tile-title>
          <v-list-tile-sub-title>{{times[index]}}</v-list-tile-sub-title>
        </v-list-tile-content>
      </v-list-tile>
    </v-list>
  </card-container>
</template>
<script>
import { getScedule } from '../MockedBackend'
import { Nations, Classes, Times } from '../LocalService'
import CardContainer from '../components/CardContainer'

export default {
  data: function () {
    return {
      classString: 'F1',
      classes: Classes,
      times: Times
    }
  },
  components: {
    CardContainer
  },
  computed: {
    nations: function () {
      var scedule = getScedule(this.classString)
      var nations = []
      scedule.forEach((nationlist) => {
        nations.push(Nations.filter((nation) => nationlist.includes(nation.index)))
      })
      return nations
    },
    maxNationCount: function () {
      var scedule = getScedule(this.classString)
      let max = 1
      scedule.forEach((nationlist) => {
        let l = nationlist.length
        max = Math.max(max, l)
      })
      return max
    }
  },
  methods: {
    getindent: function (index, rownum) { // useful for stacking avatars on multiple-nation stops
      // Index - index of avatar in row
      // Rownum - total number of avatars in row
      let maxN = this.maxNationCount
      if (typeof (index) === 'undefined') {
        return maxN + 2
      } else if (rownum === 1) {
        return (1 + maxN) / 2
      } else {
        return 1 + index / (rownum - 1) * (maxN - 1)
      }
    }
  }
}
</script>
